import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/header";
import { BsPlusLg, BsDashLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiPencil } from "react-icons/bi";
import Carousel from "react-multi-carousel";
import { Button } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import config from "../../config/config";
function Cart() {
  const { state } = useLocation();
  const [counter, setCounter] = useState(state.qty ? state.qty : 1);
  
  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const slider_data = [
    {
      title: "Lamb Shank Biryani",
      price: "S$37.40",
      slide_image: "DSCF0615.jpg",
    },
    {
      title: "Chicken Biryani",
      price: "S$16.50",
      slide_image: "DSCF0620.jpg",
    },
    {
      title: "Bundle A (2 Pax)",
      price: "S$49.90",
      slide_image: "DSCF0635.jpg",
    },
    {
      title: "Bundle B (2 Pax)",
      price: "S$49.90",
      slide_image: "DSCF0644.jpg",
    },
    {
      title: "Bundle C (2 Pax)",
      price: "S$49.90",
      slide_image: "DSCF0649.jpg",
    },
    {
      title: "Bundle C (2 Pax)",
      price: "S$49.90",
      slide_image: "DSCF0661.jpg",
    },
  ];
  const incrementCounter = () => {
    setCounter(counter + 1);
  };
         
  const decrementCounter = () => {
   if (counter !== 1) {
       setCounter(counter - 1);
    }
  };
  const placeOrder = () => {
    navigate("/customize-dish", {
      state: { item: state.item, data: state.data,qty:counter },
    });
  };
  const paymentpage = () => {
    if (config.isPaymentShow) {
      navigate("/payments");
    } else {
      navigate("/thankyou");
    }
  };
  const redirectHomepage = () => {
    navigate("/home");
  };
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Cart" />
          <div className="inner-div main-div position-absolute w-100">
            {state !== null ? (
              <>
                <div className="d-flex bg-white justify-content-between pt-2 ps-4 pb-2 pe-3">
                  <div className="d-grid">
                    <label className="public-sans-font fw-bold font-16 black-text line-height-20 mb-1">
                      Dine In
                    </label>
                    <label className="public-sans-font fw-normal font-12 grey-text line-height-17 mb-1">
                      Table-01
                    </label>
                  </div>
                  <div className="option-icon">
                    <span class="material-icons mt-3"> restaurant </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3">
                  <label className="public-sans-font fw-bold font-18 line-height-25 black-text">
                    Order summary
                  </label>
                  <div>
                    <BsPlusLg className="font-22 me-2 material-icons" />
                    <label className="public-sans-font fw-normal font-14 line-height-20 material-icons">
                      Add Item
                    </label>
                  </div>
                </div>
                <div className="bg-white">
                  <div className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3">
                    <div className="d-grid text-start">
                      <label className="public-sans-font fw-bold font-16 black-text line-height-22 mb-2">
                        {state.item.title}
                      </label>
                      <label className="public-sans-font fw-normal font-12 grey-text line-height-17 mb-1">
                        Fish Size : {state.data.size}
                      </label>
                      <label className="public-sans-font fw-normal font-12 grey-text line-height-17 mb-1">
                        Spiciness : {state.data.spiciness}
                      </label>
                      <label className="public-sans-font fw-normal font-12 black-text line-height-17 mb-1 mt-2">
                        S${state.item.price}
                      </label>
                    </div>
                    <div>
                      <img
                        src={`/assets/${process.env.REACT_APP_MODE}/img/${state.item.img}`}
                        alt=""
                        className="cart-img"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ps-4 pb-2 pe-3">
                    <div className="d-flex" onClick={placeOrder}>
                      <label className="public-sans-font fw-normal font-12 black-text line-height-17 mb-1 mt-2 edit-btn">
                        <BiPencil className="font-18 pe-1" />
                        Edit
                      </label>
                    </div>
                    <div className="mb-1 mt-2">
                      <BsDashLg className="qty-class me-3" onClick={decrementCounter} />
                      <label className="public-sans-font fw-normal font-12 black-text line-height-17">
                        {counter}
                      </label>
                      <BsPlusLg className="qty-class ms-3"  onClick={incrementCounter}/>
                    </div>
                  </div>
                </div>
                <div className="d-flex  ps-4 pb-2 pe-3 pt-3">
                  <label className="public-sans-font fw-bold font-18 line-height-25 black-text">
                    Payment Summary
                  </label>
                </div>
                <div className="bg-white d-flex justify-content-between p-4 text-start">
                  <table className="w-100">
                    <tr>
                      <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2">
                        Subtotal
                      </td>
                      <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2 text-end">
                        S$33.99
                      </td>
                    </tr>
                    <tr>
                      <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2">
                        Service Charge (10%)
                      </td>
                      <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2 text-end">
                        S$3.39
                      </td>
                    </tr>
                    <tr>
                      <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2">
                        GST 8% (Inclusive)
                      </td>
                      <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2 text-end">
                        S$2.71
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <hr className="dashed-border" />
                      </td>
                    </tr>
                    <tr>
                      <td className="public-sans-font fw-bold font-14 black-text line-height-21 pb-2 pt-2">
                        Total
                      </td>
                      <td className="public-sans-font fw-bold font-14 black-text line-height-21 pb-2 pt-2 text-end">
                        S$40.09
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        className="public-sans-font fw-normal font-12 black-text line-height-17 pt-2"
                      >
                        If you continue, you accept our{" "}
                        <Link
                          to="/profile"
                          className="privact-txt text-decoration-underline"
                        >
                          Privacy Policy
                        </Link>{" "}
                        and our{" "}
                        <Link
                          to="/profile"
                          className="privact-txt text-decoration-underline"
                        >
                          {" "}
                          Terms of Services
                        </Link>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="d-flex  ps-4 pb-2 pe-3 pt-3">
                  <label className="public-sans-font fw-bold font-18 line-height-25 black-text">
                    Recommendations for You
                  </label>
                </div>
                <div className="pt-2 bg-white pb-3 ps-2 pe-2">
                  <Carousel
                    showDots={false}
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {slider_data.map((sliderdata, index) => {
                      return (
                        <>
                          <div className="item" data-value={index}>
                            <img
                              src={`/assets/${process.env.REACT_APP_MODE}/img/${sliderdata.slide_image}`}
                              className="popular-img"
                              alt=""
                            />
                            <div className="mt-2 text-start">
                              <img src={`/assets/commonImages/${config.star}.png`} alt="" className="me-2 ms-2" />
                              <img src={`/assets/commonImages/${config.chef}.png`} alt="" />
                            </div>
                            <div className="d-grid text-start">
                              <label className="public-sans-font fw-bold font-14 black-text line-height-20 mt-3 mb-2 title-cls">
                                {sliderdata.title}
                              </label>
                              <label className="public-sans-font fw-normal font-12 black-text line-height-17">
                                {sliderdata.price}
                              </label>
                            </div>
                            <Button
                              className="border-green-btn add-btn public-sans-font fw-bold font-14 line-height-20 letter-spacing-02 mt-3"
                              type="submit"
                            >
                              Add
                            </Button>
                          </div>
                        </>
                      );
                    })}
                  </Carousel>
                </div>
                <div className="position-sticky set-width bottom-0 bg-white cart-button-div ps-3 pe-3 pt-3 pb-4">
                  <Button
                    className="fill-green-btn cart-btn mt-2 public-sans-font fw-bold font-14 line-height-21 letter-spacing-02"
                    type="submit"
                    onClick={paymentpage}
                  >
                    Place Order
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <img
                  src={`/assets/commonImages/${config.EmptyImage}.png`}
                  alt="empty Screen"
                  className="mt-5 pt-4"
                />
                <div className="d-grid mt-3 me-3 ms-3">
                  <label className="public-sans-font fw-bold font-24 black-text line-height-34 mb-1">
                    Your Cart is Empty
                  </label>
                  <label className="public-sans-font fw-normal font-14 black-text line-height-20 ms-3 me-3">
                    Looks like you haven't made your choice yet
                  </label>
                </div>
                <Button
                  className="fill-green-btn w-50 cart-btn mt-4 public-sans-font fw-bold font-14 line-height-21 letter-spacing-02"
                  type="submit"
                  onClick={redirectHomepage}
                >
                  Explore our Menu
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Cart;
