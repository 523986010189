import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { AiFillInfoCircle } from "react-icons/ai";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import Header from "../../component/header";
import config from "../../config/config";
const OrderHistory = () => {
  const navigate = useNavigate();
  const handleClick = (orderType) => {
    navigate("/order-details", {
      state: { order_type: orderType },
    });
  };
  const [data, setData] = useState([
    {
      title: "Pavilion Fish Head Curry",
      date: "INV/03/25/2023",
      price: "S$40.09",
      time: "25 Mar 2023, 7:32PM",
      order_status: "process",
      order_type: "dinein",
    },
    {
      title: "Pavilion Fish Head Curry",
      date: "INV/03/25/2023",
      price: "S$40.09",
      time: "25 Mar 2023, 7:32PM",
      order_status: "complete",
      order_type: "takeaway",
    },
    {
      title: "Lamb Shank Biryani, Pavilion Fish Head Curry",
      date: "INV/03/25/2023",
      price: "S$40.09",
      time: "25 Mar 2023, 7:32PM",
      order_status: "cancel",
      order_type: "dinein",
    },
    {
      title: "Bundle B (2 Pax)",
      date: "INV/03/25/2023",
      price: "S$56.79",
      time: "25 Mar 2023, 7:32PM",
      order_status: "complete",
      order_type: "dinein",
    },
    {
      title: "Bundle B (2 Pax)",
      date: "INV/03/25/2023",
      price: "S$56.79",
      time: "25 Mar 2023, 7:32PM",
      order_status: "complete",
      order_type: "dinein",
    },
    {
      title: "Bundle B (2 Pax)",
      date: "INV/03/25/2023",
      price: "S$56.79",
      time: "25 Mar 2023, 7:32PM",
      order_status: "complete",
      order_type: "dinein",
    },
    {
      title: "Bundle B (2 Pax)",
      date: "INV/03/25/2023",
      price: "S$56.79",
      time: "21 Mar 2023, 7:32PM",
      order_status: "cancel",
      order_type: "dinein",
    },
    {
      title: "Mutton Biryani",
      date: "INV/03/25/2023",
      price: "S$26.79",
      time: "23 Mar 2023, 7:32PM",
      order_status: "complete",
      order_type: "dinein",
    },
  ]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Order History" />
          <div className="inner-div main-div position-absolute w-100">
            <div>
              {data.length === 0 ? (
                <div>
                  <img src={`/assets/commonImages/${config.empty}.png`} alt="empty Screen" className="mt-5 pt-5" />
                  <div className="d-grid mt-5">
                    <label className="public-sans-font fw-bold font-24 black-text line-height-34 mb-1">No Order History</label>
                    <label className="public-sans-font fw-normal font-14 black-text line-height-20">You don't have any order history yet</label>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="d-flex ps-4 pt-3 pb-3">
                      <label className="public-sans-font fw-bold font-18 black-text line-height-25 pe-3">
                        Your last 10 orders
                      </label>
                      <AiFillInfoCircle
                        id="second-app-title"
                        className="info-icon-cls"
                      />
                      <ReactTooltip
                        anchorId="second-app-title"
                        place="bottom"
                        variant="info"
                        content="Only last 10 order will displayed"
                      />
                    </div>
                    <div className="bg-white">
                      {data.map((orederData, index) => {
                        return (
                          <div
                            className="pt-3 pb-3 ps-3 pe-4 div-border"
                            onClick={() => handleClick(orederData.order_type)}
                          >
                            {orederData.order_status === "complete" ? (
                              <IoCheckmarkCircle className="check-icon-color float-start mt-2" />
                            ) : orederData.order_status === "cancel" ? (
                              <IoCloseCircle className="close-icon-color float-start mt-2" />
                            ) : (
                              <img
                                src={`/assets/commonImages/${config.clock}.png`}
                                alt=""
                                className="float-start mt-2"
                              />
                            )}
                            <div className="ms-3">
                              <div className="d-flex justify-content-between ms-3 mb-2">
                                <label className="public-sans-font font-16 fw-bold line-height-22 black-text text-start d-block overflow-hidden text-nowrap order-text">
                                  {orederData.title}
                                </label>
                                <label className="public-sans-font font-16 fw-bold line-height-22 black-text text-end">
                                  {orederData.price}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between ms-3">
                                <label className="public-sans-font font-12 fw-normal line-height-17 grey-text text-start">
                                  {orederData.date}
                                </label>
                                <label className="public-sans-font font-12 fw-normal line-height-17 grey-text text-end">
                                  {orederData.time}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
