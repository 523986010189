import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import config from "../../config/config";
const Thankyou = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/orderdetails");
  };
  const handleClickHome = () => {
    navigate('/home')
  }
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="inner-div main-div position-absolute w-100">
            <div className="pt-5 mt-4">
              <img src={`/assets/commonImages/${config.flower}.png`} alt="" className="mt-5" />
            </div>
            <div className="d-grid">
              <label className="mt-4 public-sans-font line-height-25 fw-bold font-18 text-center text-color">
                Thank You!
              </label>
              <label className="mt-2 public-sans-font fw-normal line-height-20 font-14 text-center text-color">
                Your order will be ready soon.
              </label>
            </div>
            <Button
              className="fill-green-btn back-menu-btn cart-btn mt-4 public-sans-font fw-bold font-14 line-height-21 letter-spacing-02 me-4 ms-4"
              type="submit" onClick={handleClickHome}
            >
             Go back to Menu
            </Button>
            <Button
              className="fill-green-btn back-menu-btn cart-btn mt-4 public-sans-font fw-bold font-14 line-height-21 letter-spacing-02 me-4 ms-4"
              type="submit"
            >
              View Order Detail
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
