import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/header";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsPlusLg, BsDashLg } from "react-icons/bs";
function CustomizeDish() {
  const { state } = useLocation();
  const [counter, setCounter] = useState(1);
  const navigate = useNavigate();
  const [formState, setFormState] = useState(true);
  const formik = useFormik({
    initialValues: {
      size: process.env.REACT_APP_MODE === 'pavilion' ? '' : 'sadassa',
      spiciness: "",
      optional:'select',
    },
    validationSchema: Yup.object({
      size: Yup.string().required("Size is required"),
      spiciness: Yup.string().required("Spiciness is required"),
    }),
    onSubmit: (values) => {
      navigate("/cart", {
        state: { data: values,item:state.item,qty:counter },
      });
    },
  });
  const incrementCounter = () => {
    setCounter(counter + 1);
  };
         
  const decrementCounter = () => {
   if (counter !== 1) {
       setCounter(counter - 1);
    }
  };
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Customize your order" />
          <div className="inner-div main-div position-absolute w-100">
            <div className="pt-3 pe-3 ps-3 me-2 ms-2">
              <img src={`/assets/${process.env.REACT_APP_MODE}/img/${state.item.img}`} alt="" className="customize-img" />
            </div>
            <Form onSubmit={formik.handleSubmit}>
              <div className="mt-3 d-flex selection-div1">
                <div className="set-width">
                  <div className="d-grid pt-3 ps-3">
                    <label className="text-start public-sans-font font-18 fw-bold line-height-25 black-text">
                      {state.item.title}
                    </label>
                    <label className="text-start public-sans-font font-14 fw-normal line-height-25 grey-text">
                      S${state.item.price}
                    </label>
                  </div>
                  <div className="d-flex selection1  ps-3 pt-4">
                    <label className="popins-font font-16 fw-semibold line-height-25 black-text">
                     {process.env.REACT_APP_MODE === 'pavilion' ? 'Fish Size' : 'Spiciness'} <span>*</span>
                    </label>
                    <label className="dot ms-3 me-3" />
                    <label className="popins-font font-10 fw-normal line-height-25 selection-text">
                      Select 1
                    </label>
                  </div>
                  {process.env.REACT_APP_MODE === 'pavilion' ? <><Form.Group className="ps-4 pe-4 pb-3">
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`size`}
                        name="size"
                        label={`Half`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.size : "Half"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                        Free
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`size`}
                        disabled
                        name="size"
                        label={`Whole`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.size : "Whole"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-21 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-21 right-arrow position-absolute end-0 pe-4">
                        +S$16.50
                      </span>
                    </div>
                  </Form.Group></> : <><Form.Group className="ps-4 pe-4 pb-3">
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`spiciness`}
                        name="spiciness"
                        label={`Less`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.spiciness : "Less"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                        Free
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`spiciness`}
                        name="spiciness"
                        label={`Normal`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.spiciness : "Normal"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                        Free
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`spiciness`}
                        name="spiciness"
                        label={`Hot`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.spiciness : "Hot"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                        Free
                      </span>
                    </div>
                  </Form.Group></>}
                </div>
              </div>
              {process.env.REACT_APP_MODE === 'pavilion' ? <><div className="mt-3 d-flex selection-div2">
                <div className="set-width">
                  <div className="d-flex selection1  ps-3 pt-4">
                    <label className="popins-font font-16 fw-semibold line-height-25 black-text">
                      Spiciness<span>*</span>
                    </label>
                    <label className="dot ms-3 me-3" />
                    <label className="popins-font font-10 fw-normal line-height-25 selection-text">
                      Select 1
                    </label>
                  </div>
                  <Form.Group className="ps-4 pe-4 pb-3">
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`spiciness`}
                        name="spiciness"
                        label={`Less`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.spiciness : "Less"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                        Free
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`spiciness`}
                        name="spiciness"
                        label={`Normal`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.spiciness : "Normal"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                        Free
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="radio"
                        id={`spiciness`}
                        name="spiciness"
                        label={`Hot`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.spiciness : "Hot"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                        Free
                      </span>
                    </div>
                  </Form.Group>
                </div>
              </div></> : '' }
              {process.env.REACT_APP_MODE !== 'pavilion'  ? <><div className="mt-3 d-flex selection-div2">
                <div className="set-width">
                  <div className="d-flex selection1  ps-3 pt-4">
                    <label className="popins-font font-16 fw-semibold line-height-25 black-text">
                    Optional
                    </label>
                  </div>
                  <Form.Group className="ps-4 pe-4 pb-3">
                    {process.env.REACT_APP_MODE === 'gochiso' ? <><div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="checkbox"
                        id={`optional`}
                        name="optional"
                        label={`French Fries`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.optional : "French Fries"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                      +S$4.0
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="checkbox"
                        id={`optional`}
                        name="optional"
                        label={`Cabbage Salad`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.optional : "Cabbage Salad"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                      +S$3.0
                      </span>
                    </div></> : <><div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="checkbox"
                        id={`optional`}
                        name="optional"
                        label={`Japanese Cucumber/Radish Pickle`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.optional : "Japanese Cucumber/Radish Pickle"}
                        className="public-sans-font line-height-25 font-14 fw-normal black-text test-start"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                      +S$4.0
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="checkbox"
                        id={`optional`}
                        name="optional"
                        label={`French Fries`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.optional : "French Fries"}
                        className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                      +S$3.0
                      </span>
                    </div></>}
                    
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="checkbox"
                        id={`optional`}
                        name="optional"
                        label={`Edamame`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.optional : "Edamame"}
                        className="public-sans-font line-height-25 font-14 fw-normal black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                      +S$3.0
                      </span>
                    </div>
                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                      <Form.Check
                        type="checkbox"
                        id={`optional`}
                        name="optional"
                        label={`Miso Soup`}
                        onChange={formik.handleChange}
                        value={state.data ? state.data.optional : "Miso Soup"}
                        className="public-sans-font line-height-25 font-14 fw-normal black-text"
                      />
                      <span className="public-sans-font line-height-25 font-14 fw-normal line-height-20 black-text position-absolute end-0 pe-4">
                      +S$3.0
                      </span>
                    </div>
                  </Form.Group>
                </div>
              </div></> : '' }
              <div className="position-sticky set-width bottom-0 bg-white cart-button-div ps-3 pe-3 pt-4 pb-3">
                <div className="d-flex flex-row justify-content-between">
                  <span className="public-sans-font black-text fw-bold font-14 line-height-20">
                    Item quantity
                  </span>
                  <div className="d-flex align-items-center">
                    <BsDashLg className="qty-class me-3 disable" onClick={decrementCounter} />
                    <label className="public-sans-font fw-normal font-12 black-text line-height-17">
                    {counter}
                    </label>
                    <BsPlusLg className="qty-class ms-3" onClick={incrementCounter}/>
                  </div>
                </div>
                <Button
                  className="fill-green-btn cart-btn mt-4 public-sans-font fw-bold font-14 line-height-21 letter-spacing-02"
                  type="submit"
                  onClick={(values) => setFormState(values)}
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Add to cart - S$ 33.99
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomizeDish;
