const pavilion = {
    isPaymentShow:true,
    isLoginShow:false,
    isDineOption:true,
    EmptyImage:'empty_cart',
    star:'star',
    chef:'chef',
    empty:'empty',
    clock:'clock',
    payment:'payment',
    apple:'apple',
    google:'google',
    flower:'flower',
    search:'search',
  };
  
  const gochiso = {
    isPaymentShow:false,
    isLoginShow:false,
    isDineOption:true,
    EmptyImage:'empty_cart',
    star:'star',
    chef:'chef',
    empty:'empty',
    clock:'clock',
    payment:'payment',
    apple:'apple',
    google:'google',
    flower:'flower',
    search:'search',
  };
  const butakizoku = {
    isPaymentShow:false,
    isLoginShow:false,
    isDineOption:false,
    EmptyImage:'empty_cart',
    star:'star',
    chef:'chef',
    empty:'empty',
    clock:'clock',
    payment:'payment',
    apple:'apple',
    google:'google',
    flower:'flower',
    search:'search',
  };
 const config = process.env.REACT_APP_MODE === "pavilion" ? pavilion : process.env.REACT_APP_MODE === "gochiso" ? gochiso : butakizoku;
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default {
    ...config,
  };