import React, { useEffect, useState } from "react";
import Header from "../../component/header";
import CarouselBanner from "./CarouselBanner";
import Scrollspy from "react-scrollspy-ez";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineShoppingCart } from "react-icons/md";
import { SlBag } from "react-icons/sl";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Button } from "react-bootstrap";
import TimeKeeper from "react-timekeeper";
import { CustomDialog } from "react-st-modal";
import config from "../../config/config";
import { storeMenuItems, storeStockList } from "../../services/tableQRServices";
import _ from "lodash";

function Home() {
  const navigate = useNavigate();
  const [isBottomOpen, setIsBottomOpen] = useState(false);
  const [enableOverlay, setEnableOverlay] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [time, setTime] = useState("12:34pm");
  const [gochiMenuCategories, setGochiMenuCategories] = useState([]);
  const [gochiMenuItems, setGochiMenuItems] = useState([]);
  useEffect(() => {
    if (config.isDineOption) {
      let getStorageOption = localStorage.getItem("selectiDineOption");
      if (getStorageOption === "" || getStorageOption === null) {
        setIsBottomOpen(true);
      }
    }
  }, []);

  useEffect(() => {
    storeStockList()
      .then((res) => {
        const result = res?.data?.data[0]?.output;
        const resultParse = JSON.parse(result);
        let categoryCodes = [];
        for (const category of resultParse.filter(
          (category) => category.avl_type === "C"
        )) {
          categoryCodes.push(category.item_category);
        }
        let resultItems1 = resultParse.filter(
          (category) => category.avl_type === "I"
        );
        setGochiMenuCategories(categoryCodes);
        storeMenuItems()
          .then((res) => {
            const result = res?.data?.data[0]?.output;
            _.intersectionBy(
              resultItems1,
              result,
              (obj) => obj.item_category || obj.item_no
            );

            let intersectItems = _.intersectionBy(
              resultItems1,
              result,
              (obj) => obj.item_category || obj.item_no
            ).filter((item) => item.is_active);
            intersectItems = Object.values(
              _.merge(
                _.keyBy(resultItems1, "item_category"),
                _.keyBy(result, "item_no")
              )
            ).filter((item) => item.is_active);

            intersectItems.sort(
              (a, b) =>
                categoryCodes.indexOf(a.category_code) -
                categoryCodes.indexOf(b.category_code)
            );
            setGochiMenuItems(intersectItems);
          })

          .catch((err) => {});
      })
      .catch((err) => {});
  }, []);

  let menuItem, menuDish;
  if (process.env.REACT_APP_MODE === "pavilion") {
    menuItem = [
      "Best Seller",
      "Order Again",
      "For You",
      "Bundle Meals",
      "Biryani & Rice",
      "Sea Food",
      "South Indian Dishes",
      "North Indian Dishes",
      "Tandoori Dishes",
      "Starter",
      "Tandoori Breads",
    ];
    menuDish = [
      {
        img: "dish1.png",
        title: "Pavilion Fish Head Curry",
        price: "33.99",
        category_code: "Best Seller",
        iaAvailable: true,
        isBestSeller: true,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish2.png",
        title: "Lamb Shank Biryani",
        price: "37.40",
        category_code: "Best Seller",
        iaAvailable: true,
        isBestSeller: true,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish3.png",
        title: "Chicken Biryani",
        price: "33.99",
        category_code: "Best Seller",
        iaAvailable: false,
        isBestSeller: true,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish4.png",
        title: "Chili Chicken Biryani",
        price: "33.99",
        category_code: "Order Again",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish5.png",
        title: "Bundle A (2 Pax)",
        price: "33.99",
        category_code: "For You",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish6.png",
        title: "Bundle B (2 Pax)",
        price: "33.99",
        category_code: "Bundle Meals",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish7.png",
        title: "Bundle C (2 Pax)",
        price: "33.99",
        category_code: "Biryani & Rice",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish1.png",
        title: "Bundle D (4 Pax)",
        price: "33.99",
        category_code: "Sea Food",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish3.png",
        title: "Chicken Biryani",
        price: "33.99",
        category_code: "Sea Food",
        iaAvailable: false,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish4.png",
        title: "Chili Chicken Biryani",
        price: "33.99",
        category_code: "Sea Food",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish5.png",
        title: "Bundle A (2 Pax)",
        price: "33.99",
        category_code: "South Indian Dishes",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish6.png",
        title: "Bundle B (2 Pax)",
        price: "33.99",
        category_code: "North Indian Dishes",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
        is_soldout: "Y",
      },
      {
        img: "dish7.png",
        title: "Bundle C (2 Pax)",
        price: "33.99",
        category_code: "North Indian Dishes",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish3.png",
        title: "Chicken Biryani",
        price: "33.99",
        category_code: "Tandoori Dishes",
        iaAvailable: false,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish4.png",
        title: "Chili Chicken Biryani",
        price: "33.99",
        category_code: "Tandoori Dishes",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish5.png",
        title: "Bundle A (2 Pax)",
        price: "33.99",
        category_code: "Starter",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish6.png",
        title: "Bundle B (2 Pax)",
        price: "33.99",
        category_code: "Tandoori Breads",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish7.png",
        title: "Bundle C (2 Pax)",
        price: "33.99",
        category_code: "Tandoori Breads",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
    ];
  } else if (process.env.REACT_APP_MODE === "gochiso") {
    menuItem = gochiMenuCategories;
    menuDish = gochiMenuItems;
  } else {
    menuItem = [
      "Best Seller",
      "Order Again",
      "For You",
      "Starter",
      "Primium",
      "Sake",
      "Assorted Series",
      "Cheese Series",
      "Mashroom Series",
      "Chicken",
      "Beer & Highballs",
      "Cocktails",
      "Juices",
      "Sides",
    ];
    menuDish = [
      {
        img: "dish1.png",
        title: "Pavilion Fish Head Curry",
        price: "33.99",
        category_code: "Best Seller",
        iaAvailable: true,
        isBestSeller: true,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish2.png",
        title: "Lamb Shank Biryani",
        price: "37.40",
        category_code: "Best Seller",
        iaAvailable: true,
        isBestSeller: true,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish3.png",
        title: "Chicken Biryani",
        price: "33.99",
        category_code: "Best Seller",
        iaAvailable: false,
        isBestSeller: true,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish4.png",
        title: "Chili Chicken Biryani",
        price: "33.99",
        category_code: "Order Again",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish5.png",
        title: "Bundle A (2 Pax)",
        price: "33.99",
        category_code: "For You",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish6.png",
        title: "Bundle B (2 Pax)",
        price: "33.99",
        category_code: "Starter",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish7.png",
        title: "Bundle C (2 Pax)",
        price: "33.99",
        category_code: "Primium",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish1.png",
        title: "Bundle D (4 Pax)",
        price: "33.99",
        category_code: "Sake",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish3.png",
        title: "Chicken Biryani",
        price: "33.99",
        category_code: "Assorted Series",
        iaAvailable: false,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish4.png",
        title: "Chili Chicken Biryani",
        price: "33.99",
        category_code: "Assorted Series",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish5.png",
        title: "Bundle A (2 Pax)",
        price: "33.99",
        category_code: "Cheese Series",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish6.png",
        title: "Bundle B (2 Pax)",
        price: "33.99",
        category_code: "Mashroom Series",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
        is_soldout: "Y",
      },
      {
        img: "dish7.png",
        title: "Bundle C (2 Pax)",
        price: "33.99",
        category_code: "Chicken",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish3.png",
        title: "Chicken Biryani",
        price: "33.99",
        category_code: "Beer & Highballs",
        iaAvailable: false,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish4.png",
        title: "Chili Chicken Biryani",
        price: "33.99",
        category_code: "Beer & Highballs",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish5.png",
        title: "Bundle A (2 Pax)",
        price: "33.99",
        category_code: "Cocktails",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish6.png",
        title: "Bundle B (2 Pax)",
        price: "33.99",
        category_code: "Juices",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
      {
        img: "dish7.png",
        title: "Bundle C (2 Pax)",
        price: "33.99",
        category_code: "Sides",
        iaAvailable: true,
        isBestSeller: false,
        isSignature: false,
        isTry: false,
      },
    ];
  }
  const placeOrder = (dishDetail) => {
    navigate("/customize-dish", {
      state: { item: dishDetail },
    });
  };
  const toggleBottom = () => {
    setIsBottomOpen((prev) => !prev);
  };
  const selectDining = (selectionOption) => {
    localStorage.setItem("selectiDineOption", JSON.stringify(selectionOption));
    setIsBottomOpen(false);
  };
  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
  };
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header
            title="home"
            showSearchBar={showSearchBar}
            handleSearchClick={handleSearchClick}
          />
          <div className="main-div home-div position-absolute w-100">
            <CarouselBanner />
            <div className="mt-4 top-border">
              <Scrollspy
                ids={menuItem}
                itemContainerClassName="scrollSpyContainer menu-cls"
                activeItemClassName="active"
                itemClassName="spyItemClass public-sans-font font-12 fw-bold line-height-17"
                offset={100}
                includeParentClasses={true}
              />
              <div className="fix-width ps-3 pe-3">
                {menuDish.length > 0 ? (
                  <>
                    {menuDish.map((menuDishes, index) => {
                      return (
                        <>
                          <div
                            id={menuDishes.category_code}
                            style={{ color: "#f0f8ff00" }}
                          >
                            {menuDishes.category_code}
                          </div>
                          <img
                            src={`/assets/${process.env.REACT_APP_MODE}/img/${menuDishes.img}`}
                            className="carousel-cls"
                            alt=""
                          />
                          {menuDishes.isBestSeller === true ? (
                            <div className="position-absolute d-flex best-seller-tag ms-2">
                              <span className="font-10 public-sans-font fw-bold text-white line-height-14">
                                Best Seller
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="small-icons position-absolute ps-2">
                            {menuDishes.is_soldout === "Y" ? (
                              <div className="sold-out-tag d-flex">
                                <span className="font-10 public-sans-font fw-normal text-white line-height-14">
                                  Sold Out
                                </span>
                              </div>
                            ) : (
                              <>
                                <img
                                  src={`/assets/commonImages/${config.star}.png`}
                                  alt=""
                                  className="ms-1"
                                />
                                <img
                                  src={`/assets/commonImages/${config.chef}.png`}
                                  alt=""
                                  className="ms-1"
                                />
                              </>
                            )}
                          </div>
                          <div className="d-grid text-start">
                            <span className="public-sans-font font-14 fw-bold line-height-20 black-text mt-3 text-break">
                              {menuDishes.title}
                            </span>
                            <span className="public-sans-font font-12 fw-normal line-height-17 black-text mt-2 price-text">
                              S$
                              {
                                menuDishes?.selling_uom_dtls[0]?.price_dtls[0]
                                  ?.dine_in_price
                              }
                            </span>
                          </div>

                          <button
                            className="border-green-btn public-sans-font font-14 fw-bold line-height-17 green-100 mb-2 mt-4"
                            onClick={() => placeOrder(menuDishes)}
                          >
                            Add
                          </button>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div>
                    <img
                      src={`/assets/commonImages/${config.search}.png`}
                      alt=""
                      className="mt-5"
                    />
                    <div className="d-grid">
                      <label className="public-sans-font font-18 fw-bold line-height-25 black-text">
                        No Result
                      </label>
                      <label className="public-sans-font font-14 fw-normal line-height-20 black-text">
                        Sorry we couldn't find any result, try another keyword.
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Link to="/cart">
              <button className="fill-green-btn sticky-btn position-sticky public-sans-font font-14 fw-bold line-height-17 mb-2 mt-4 me-4 ms-4">
                {process.env.REACT_APP_MODE === "pavilion" ? (
                  <>
                    <div className="d-flex justify-content-between pt-1">
                      <div className="ms-3">
                        <MdOutlineShoppingCart className="font-22 text-white" />
                        <span className="public-sans-font font-14 fw-bold line-height-21 text-white ms-3">
                          0 Items
                        </span>
                      </div>
                      <div className="public-sans-font font-14 fw-bold line-height-21 text-white text-end position-absolute start-0 end-0 pe-4">
                        S$ 0
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="cart-btn-gochiso">
                      <label className="public-sans-font font-14 fw-bold line-height-21 text-white position-absolute text-center start-0 end-0 pe-4 ">
                        View Cart
                      </label>
                      <label className="public-sans-font font-14 fw-bold line-height-21 text-white position-absolute text-end end-0 pe-4">
                        S$ 0
                      </label>
                    </div>
                    {/* <div className="public-sans-font font-14 fw-bold line-height-21 text-white text-end  start-0 end-0 pe-4">
                      S$ 0
                    </div>
                    <div className="public-sans-font font-14 fw-bold line-height-21 text-white text-center  start-0 pe-4">
                      S$ 0
                    </div> */}
                  </>
                )}
              </button>
            </Link>
            <Drawer
              open={isBottomOpen}
              onClose={toggleBottom}
              direction="bottom"
              size={300}
              enableOverlay={enableOverlay}
            >
              <div>
                <label className="d-flex pt-3 ps-3 public-sans-font font-20 fw-bold line-height-25">
                  Select your dining experience
                </label>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <span class="material-icons pt-3"> restaurant </span>
                    </div>
                    <label className="public-sans-font font-18 fw-bold line-height-25 black-text mt-3 ms-3">
                      Dine in
                    </label>
                    <Button
                      className="fill-green-btn cart-btn dining-btn mt-2 public-sans-font fw-bold font-14 line-height-21 letter-spacing-02 position-absolute end-0 me-4 mt-2"
                      type="submit"
                      onClick={() => selectDining("dinein")}
                    >
                      Select
                    </Button>
                  </div>
                </div>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <SlBag className="material-icons mt-3 font-18" />
                    </div>
                    <label className="public-sans-font font-18 fw-bold line-height-25 black-text mt-3 ms-3">
                      Takeaway
                    </label>
                    <Button
                      className="fill-green-btn cart-btn dining-btn mt-2 public-sans-font fw-bold font-14 line-height-21 letter-spacing-02 position-absolute end-0 me-4 mt-2"
                      type="submit"
                      onClick={async () => {
                        localStorage.setItem("selectiDineOption", "takeaway");
                        setIsBottomOpen(false);
                        const result = await CustomDialog(
                          <TimeKeeper
                            time={time}
                            onChange={(newTime) => setTime(newTime.formatted12)}
                            switchToMinuteOnHourSelect
                            disabledTimeRange={{ from: "4:20", to: "20:45" }}
                          />,
                          {
                            title: "Custom Dialog",
                            showCloseIcon: true,
                          }
                        );
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
