import React, { useEffect } from "react";
import { AnimateOnChange } from "react-animation";
import { useNavigate } from "react-router-dom";
import { userSessionId } from "../../services/tableQRServices";
import { STORAGEKEY } from "../../constants";

function SplashScreen() {
  const navigate = useNavigate();
  let params = window.location.search;
  const storeName = new URLSearchParams(params).get("storename");
  const tableNo = new URLSearchParams(params).get("tableno");
  localStorage.setItem(
    STORAGEKEY.STORE_NAME,
    storeName && storeName.length > 0 ? storeName : "BUGIS JUNCTION"
  );
  localStorage.setItem(STORAGEKEY.TABLE_NO, tableNo);
  useEffect(() => {
    userSessionId()
      .then((res) => {
        const sessionId =
          res &&
          res.data &&
          res.data.data[0] &&
          res.data.data[0].output[0].session_id;

        localStorage.setItem(STORAGEKEY.SESSION_ID, sessionId);
      })
      .catch((_err) => {});
  }, []);
  setTimeout(function () {
    navigate("/home");
  }, 10000);
  return (
    <>
      <div className="App">
        <AnimateOnChange animationIn="fadeIn" durationIn={600}>
          <div className="qr-before-body w-100">
            <div className="title-bg">
              {process.env.REACT_APP_MODE === "pavilion" ? (
                <label className="position-absolute start-0 end-0 title-margin font-28 fw-bold line-height-45 hotel-title">
                  The Authentic <br />
                  South Indian Restaurant
                </label>
              ) : (
                <img
                  src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
                  alt=""
                  className="mt-5 pt-5"
                />
              )}
            </div>
            <div className="h-50 position-absolute img-div-height w-100">
              <img
                src={`/assets/${process.env.REACT_APP_MODE}/background/splash.png`}
                alt=""
                className="w-100 img-height"
              />
            </div>
          </div>
        </AnimateOnChange>
      </div>
    </>
  );
}

export default SplashScreen;
