import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../config/config";
import Header from "../../component/header";

const Paymentsuccess = () => {
  const [formState, setFormState] = useState(true);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      console.log("Form Values :", values);
      navigate("/thankyou");
    },
  });
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Payments" />
          <div className="inner-div payment-div position-absolute w-100">
            <p className="text-center public-sans-font black-text fw-normal font-14 line-height-20 mt-5">
              Your payment was successful!
            </p>
            <div>
              <div className="me-4 pe-3 ps-3 ms-4">
                <div className="d-flex justify-content-between payment-border position-relative">
                  <label className="public-sans-font font-14 fw-bold line-height-21 text-green-cls">
                    Table 1
                  </label>
                  <label className="public-sans-font font-14 fw-bold line-height-21 text-green-cls">
                    Fully Paid
                  </label>
                </div>
                <div className="border-cls me-4 ms-4"></div>
                <div className="d-flex justify-content-between payment-border-bottom">
                  <div className="d-grid">
                    <label className="public-sans-font black-text fw-bold font-12 line-height-17 mb-4">
                      You paid S$31.81
                    </label>
                    <label className="public-sans-font text-color fw-normal font-12 line-height-17 mb-4">
                      Transaction date
                    </label>
                    <label className="public-sans-font fw-bold font-12 line-height-17 bill-text mb-2">
                      You fully paid your bill
                    </label>
                  </div>
                  <div className="d-grid text-end">
                    <label>
                      <IoIosArrowUp className="grey-light-color arrow-color font-22" />
                    </label>
                    <label className="public-sans-font text-color fw-normal font-12 line-height-17 mb-4">
                      1:57pm, 14/3/2023
                    </label>
                    <label></label>
                  </div>
                </div>
              </div>
              <div className="bg-white mt-4 pb-4 email-section">
                <label className="public-sans-font text-color fw-normal font-12 line-height-17 mt-4 ms-4 pt-4 d-flex text-start">
                  Get your receipt by email
                </label>
                <Form onSubmit={formik.handleSubmit} className="ms-4 me-4 mt-3">
                  <Form.Group controlId="email">
                    <div className="d-flex">
                      <Form.Control
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="Your E-mail Address"
                        className="public-sans-font fw-normal font-14 line-height-20 text-color"
                      />
                      <Button
                        className="public-sans-font fw-bold font-14 line-height-22 letter-spacing-02 position-absolute text-end send-btn"
                        type="submit"
                        onClick={(values) => setFormState(values)}
                        disabled={!(formik.isValid && formik.dirty)}
                      >
                        Send
                      </Button>
                    </div>
                    <Form.Text>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger public-sans-font fw-normal font-12 line-height-17 mt-2">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Form.Group>
                </Form>
                <label className="public-sans-font text-color fw-normal font-12 line-height-17 mt-4 ms-4 d-flex">
                  How was your dining experience?
                </label>
                <Button className="ms-4 me-4 review-btn mt-4" type="submit">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <img src={`/assets/commonImages/${config.google}.png`} alt="" />
                    </div>
                    <div className="public-sans-font fw-bold font-14 line-height-21 letter-spacing-02 text-color text-start me-3 ps-0 btn-margin">
                      Review this place on Google
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paymentsuccess;
