import React from "react";
import Header from "../../component/header";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Button } from "react-bootstrap";

const Orderdetails = () => {
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Order Detail" />
          <div className="inner-div main-div position-absolute w-100">
            <div className="bg-white">
              <div className="d-flex  justify-content-between pt-3 ps-4 pb-3 pe-3 div-border text-start">
                <div className="d-grid">
                  <label className="public-sans-font fw-bold font-16 black-text line-height-22 mb-1">
                    INV/03/25/2023
                  </label>
                  <label className="public-sans-font fw-normal font-12 grey-text line-height-17">
                    25 Mar 2023, 7:32PM
                  </label>
                </div>
                <div className="mt-2 me-2">
                  <IoCheckmarkCircle className="check-icon-color" />
                </div>
              </div>
              <div className="d-flex  justify-content-between pt-2 ps-4 pb-2 pe-3 text-start">
                <div className="d-grid">
                  <label className="public-sans-font fw-bold font-16 black-text line-height-22">
                    Dine In
                  </label>
                  <label className="public-sans-font fw-normal font-12 grey-text line-height-17">
                    Table-01
                  </label>
                </div>
                <div className="option-icon">
                  <span class="material-icons mt-3 ms-2 ps-2">
                    {" "}
                    restaurant{" "}
                  </span>
                </div>
              </div>
            </div>
            <label className="ps-4 mt-3 mb-3 public-sans-font fw-bold font-18 line-height-25 black-text d-flex">
              Order Summary
            </label>
            <div className="bg-white ps-4 pb-3 pe-3 div-border">
              <div className="d-flex pt-3">
                <div className="leftbox me-2">
                  <label className="public-sans-font fw-bold font-16 material-icons">
                    1x
                  </label>
                </div>
                <div className="middlebox d-grid text-start">
                  <label className="public-sans-font fw-bold font-14 black-text line-height-20 mb-2">
                    Pavilion Fish Head Curry
                  </label>
                  <label className="public-sans-font fw-normal font-10 grey-text line-height-17 mb-1">
                    Fish Size : Half
                  </label>
                  <label className="public-sans-font fw-normal font-10 grey-text line-height-17 mb-1">
                    Spiciness : Normal
                  </label>
                  <label className="public-sans-font fw-normal font-12 black-text line-height-17 mb-1">
                    S$33.99
                  </label>
                </div>
                <div className="rightbox text-end ms-2">
                  <Button
                    className="border-green-btn public-sans-font fw-bold font-14 line-height-21 letter-spacing-02"
                    type="submit"
                  >
                    Reorder
                  </Button>
                </div>
              </div>
            </div>
            <label className="ps-4 mt-3 mb-3 public-sans-font fw-bold font-18 line-height-25 text-black d-flex">
            Payment Summary
          </label>
          <div className="bg-white d-flex justify-content-between pt-4 pe-4 ps-4 pb-2 mb-4">
            <table className="w-100 text-start">
              <tr>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2">
                  {" "}
                  Payment Method
                </td>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2 text-end">
                  Qlub
                </td>
              </tr>
              <tr>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2">
                  Subtotal
                </td>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2 text-end">
                  S$33.99
                </td>
              </tr>
              <tr>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2">
                  Service Charge (10%)
                </td>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17 pb-2 text-end">
                  S$3.39
                </td>
              </tr>
              <tr>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17">
                  GST 8% (Inclusive)
                </td>
                <td className="public-sans-font fw-normal font-12 black-text line-height-17 text-end">
                  S$2.71
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr className="dashed-border" />
                </td>
              </tr>
              <tr>
                <td className="public-sans-font fw-bold font-14 black-text line-height-20">
                  Total
                </td>
                <td className="public-sans-font fw-bold font-14 black-text line-height-20 text-end">
                  S$40.09
                </td>
              </tr>
            </table>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Orderdetails;
