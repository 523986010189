import React from 'react';

function Sidebar() {
  return (
    <>
      <div className="qr-large-device-left position-fixed top-50">
        <img
          src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
          alt="Desktop Logo"
          className='mw-100 h-auto'
        />
        <h5 className="qr-large-device-text text-white font-22 public-sans-font">Table QR Ordering System</h5>
      </div>
      <div className="qr-large-device-right position-fixed start-50 w-100">
        <h5 className="qr-large-device-scan-msg text-center text-white public-sans-font line-height-21 font-18 fw-normal">Scan here for mobile view</h5>
        <div className="qr-large-device-scan-box bg-white ms-auto">
          <img
            src={`/assets/commonImages/qr_code.png`}
            alt="QR Code"
            className='mw-100 h-auto'
          />
        </div>
        <div className="qr-large-device-scan-arrow w-100">
          <img
            src={`/assets/commonImages/qr-scan-arrow.png`}
            alt="QR Arrow"
            className='mw-100 w-100'
          />
        </div>
      </div>
    </>
  );
}
export default Sidebar;
