import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

const Pavilion = React.lazy(() => import("../src/dynamic_css/pavilion.js"))
const Gochiso = React.lazy(() => import("../src/dynamic_css/gochiso.js"))
const Butakizoku = React.lazy(() => import("../src/dynamic_css/butakizoku.js"))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {process.env.REACT_APP_MODE === "pavilion" && <Pavilion />}
      {process.env.REACT_APP_MODE === "gochiso" && <Gochiso />}
      {process.env.REACT_APP_MODE === "butakizoku" && <Butakizoku />}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
