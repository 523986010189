import React, { useState } from "react";
import { HiArrowSmLeft } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineLocalDining, MdOutlineGrading } from "react-icons/md";
import { Offcanvas } from "react-bootstrap";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { TbRefreshDot } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { RiCloseCircleFill } from "react-icons/ri";
import { Collapse, Form } from "react-bootstrap";
function Header(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const closeSidebar = () => setShow(false);
  const showSidebar = () => setShow(true);
  const [query, setQuery] = useState("");
  return (
    <>
      <div
        className={
          props.title === "home"
            ? "d-flex w-100 top-0 start-0 position-sticky header-cls"
            : "d-flex w-100 top-0 start-0 position-sticky header-inner-cls"
        }
      >
        {props.title === "home" ? (
          <>
            {!props.showSearchBar ? (
              <>
                <div className="d-flex top-0 start-0 w-100 position-absolute side-menu">
                  <div className="position-absolute start-0 ps-4 ms-2 pt-3">
                    <GiHamburgerMenu
                      className="font-22 text-white pointer-cls"
                      onClick={showSidebar}
                    />
                    <Offcanvas
                      backdrop="false"
                      show={show}
                      onHide={closeSidebar}
                    >
                      <Offcanvas.Header
                        closeButton
                        className="position-absolute w-100 side-menu-header"
                      >
                        <Offcanvas.Title className="ps-2">
                          <img
                            src={`/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`}
                            alt="Logo"
                            className={
                              process.env.REACT_APP_MODE === "pavilion"
                                ? "img-size"
                                : ""
                            }
                          />
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <div className="position-absolute w-100 side-menu-body pointer-cls">
                          <div className="d-flex ps-4 pe-4 w-100">
                            <div>
                              <MdOutlineLocalDining className="material-icons" />
                            </div>
                            <div className="ps-4 fw-normal font-16 public-sans-font">
                              Menu
                            </div>
                            <Link to="/order-history">
                              <div className="position-absolute end-0 pe-4">
                                <BiChevronRight className="font-28 right-arrow" />
                              </div>
                            </Link>
                          </div>
                          <hr className="ms-4 me-4 bttom-border" />
                          <div className="d-flex ps-4 pe-4 w-100">
                            <div>
                              <AiOutlineShoppingCart className="material-icons" />
                            </div>
                            <div className="ps-4 fw-normal font-16 public-sans-font">
                              Order
                            </div>
                            <Link to="/order-history">
                              <div className="position-absolute end-0 pe-4">
                                <BiChevronRight className="font-28 right-arrow" />
                              </div>
                            </Link>
                          </div>
                          <hr className="ms-4 me-4 bttom-border" />
                          <div className="d-flex ps-4 pe-4 w-100">
                            <div>
                              <MdOutlineGrading className="material-icons" />
                            </div>
                            <div className="ps-4 fw-normal font-16 public-sans-font">
                              Order History
                            </div>
                            <Link to="/order-history">
                              <div className="position-absolute end-0 pe-4">
                                <BiChevronRight className="font-28 right-arrow" />
                              </div>
                            </Link>
                          </div>
                          {process.env.REACT_APP_MODE === "pavilion" ? (
                            <>
                              <hr className="ms-4 me-4 bttom-border" />
                              <div className="d-flex ps-4 pe-4 w-100">
                                <div>
                                  <TbRefreshDot className="material-icons" />
                                </div>
                                <div className="ps-4 fw-normal font-16 public-sans-font">
                                  Change Dining Option
                                </div>
                                <div className="position-absolute end-0 pe-4">
                                  <BiChevronRight className="font-28 right-arrow" />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </div>
                  <div>
                    <img
                      src={`/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`}
                      alt="Logo"
                      className="nav-logo mb-3"
                    />
                  </div>
                  <div className="position-absolute end-0 pe-4 me-2 pt-3">
                    <AiOutlineSearch
                      className="font-28 text-white fw-bold"
                      onClick={props.handleSearchClick}
                    />
                  </div>
                </div>
              </>
            ) : (
              <Collapse in="true">
                <div className="d-flex align-items-center search-form mb-4 mt-2 me-3 ms-3">
                  <AiOutlineSearch className="input-search-icon ms-2" />
                  <input
                    placeholder="Search Menus"
                    className="search-input font-family-class"
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  <RiCloseCircleFill
                    onClick={props.handleSearchClick}
                    className="close-search font-24 position-fixed text-white end-0 me-3"
                  />
                </div>
                {/* <Form
        inline
        className="d-flex align-items-center search-form mb-4 mt-2 me-3 ms-3"
      >
        <AiOutlineSearch className="input-search-icon ms-2" />

        <Form.Control
          type="text"
          placeholder="Search menus"
          className="search-input font-family-class"
        />

        <RiCloseCircleFill
          onClick={props.handleSearchClick}
          className="close-search font-24 position-fixed text-white end-0 me-3"
        />
      </Form> */}
              </Collapse>
            )}
          </>
        ) : (
          <>
            <div className="text-white font-28 flex-grow-1">
              <div
                className="position-absolute arrow-top"
                onClick={() => navigate(-1)}
              >
                <HiArrowSmLeft />
              </div>
              <div className="font-14 fw-bold line-height-20 public-sans-font">
                {props.title}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Header;
