import moment from "moment";
import api from "../api/api";
import * as url from "../api/url";
import { STORAGEKEY } from "../constants";

export const userSessionId = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_SESSION_ID, {
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const storeMenuItems = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_HOME_CATEGORY, {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const storeStockList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_STORE_STOCK_LIST, {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
        dayname: moment().format("dddd"),
        logindate: moment().format("YYYY/MM/DD"),
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
